z.ui.dividing.header {
  //background: #e8e8e8;
  padding-top: 10px;
  padding-right: 10px;
}

.ui.main.menu {
  height: var(--top-bar-height);
  //border-bottom: 2px solid #4178be !important;
  width: 100%;

  .item {
    padding: .9em .8em;
    .icon.m0 {
      margin: 0;
    }
    &:hover {
      background: #fff;
      color: #4178be;
      i.icon {
        color: #4178be;
      }
    }
  }
  .item:before {
    background: none;
  }
  i.icon,
  i.icons {
    font-size: 1.7em;
  }
}

.ui.main.menu .item.flex-1 {
  flex: 1;
}

.top-search-component {
  width: 50%;

  .ui.input {
    width: 100%;

    &::after {
      position: absolute;
      top: 0.8rem;
      right: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5rem;
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      color: #6c757d;
      content: "S";
      border: 1px solid #dee2e6;
      border-radius: 0.125rem;
      //font-size: .75rem;
      font-size: 1rem;
      direction: ltr;
    }
  }

  .prompt {
    border: none !important;
    border-left: 0.15em solid #dff3ff !important;
    background-color: #f7fcff !important;
    border-radius: 0.28571429rem !important;
  }

  .search.link.icon {
    display: none;
  }


}

.ui.dropdown.selection>.text {
  display: block;
}

.ui.dropdown .menu .message {
  font-size: 1.1em;
}

.ui.dropdown .menu>.item.red {
  //background: #db2828;
  color: #db2828;

  &:hover {
    background: #c84343;
    color: #fff;
  }
}

.ui.dropdown .menu>.menu-items .item {
  position: relative;
  cursor: pointer;
  //display: block;
  height: auto;
  text-align: right;
  border-style: none;
  line-height: 1em;
  color: rgba(0,0,0,.87);
  padding: .78571429rem 1.14285714rem !important;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
}


.ui.menu .ui.dropdown .menu>.menu-items .item {
  margin: 0;
  background: 0 0!important;
  transition: none!important
}

.ui.menu .ui.dropdown .menu>.menu-items .item:hover {
  background: rgba(0,0,0,.05)!important;
  color: rgba(0,0,0,.95)!important
}

.ui.menu .ui.dropdown.item .menu .item:only-of-type {
  display: flex !important;
}


.ui.menu .item>i.icon {
  margin: 0 0 0 .35714286em;
}

// RTL Semantic
.ui.dropdown .menu {
  text-align: right;
}


.large.menu {
  width: 200px;
}

.ui.header {
  font-family: var(--header-font-family);

  &.open-sans {
    @extend .open-sans;
  }
}


.ui.form .field>label {
  margin: 0 0 .45rem 0;
}

.ui.form .inline.field>:first-child, .ui.form .inline.fields .field>:first-child {
  margin: 0 0 0 .85714286em;
}

.ui.form .grouped.fields {
  margin: 0 0 2em;
}

.ui.form {
  input[type=date] {
    padding: 1.3rem 0.8rem;
  }

  input:not([type]),
  input[type=date],
  input[type=datetime-local],
  input[type=email],
  input[type=file],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url] {
    padding: .9em 1em;
  }

  .field>label {
    margin: 0 0 .75rem;
  }
}


.ui.form input[type="text"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="url"]:focus,
.ui.form input[type="tel"]:focus {
  box-shadow: -1px 0em 0em 0em rgba(39, 41, 43, 0.3) ins2t;
}

.ui.form textarea:focus {
  box-shadow: -1px 0em 0em 0em rgba(39, 41, 43, 0.3) ins2t;
}


//.ui.form .fields .field:first-child {
//  padding-right: 0;
//  padding-left: 0.5em;
//}

.ui.form .fields {
  clear: both;
  margin-bottom: 1.5em;
  //margin-right: 0;
}

.ui.form .fields>.field {
  // float: right;
}

.error.field .popup {
  // background-color: #ffe8e6;
  color: #db2828;
}


/*
  Launch button
 */

// .fixed.launch.button {
//   position: fixed;
//   top: 0px;
//   right: -1px;
//   width: 55px;
//   height: auto;
//   white-space: nowrap;
//   overflow: hidden;
//   transition: 0.3s width ease, 0.5s transform ease;
// }
// .launch.button .icon {
//   margin-right: 0px;
//   position: relative;
//   // left: 15px;
// }
// .fixed.launch.button .text {
//   position: absolute;
//   white-space: nowrap;
//   top: auto;
//   right: 54px;
//   opacity: 0;
//   transition: 0.3s opacity 0.3s;
// }



.ui.button {
  margin: 0 0 0 .25em;
}

.ui.button {
  &.mts {
    margin-top: 20px;
  }
  &.mtm {
    margin-top: 40px;
  }
  &.mtl {
    margin-top: 60px;
  }
  &.mbs {
    margin-bottom: 20px;
  }
  &.mbm {
    margin-bottom: 40px;
  }
  &.mbl {
    margin-bottom: 60px;
  }
}

.ui.search.selection.dropdown>input.search {
  // direction: ltr;
  //padding-right: 35px;
  //padding: .9em 2.1em .9em 1em;
}

@media only screen and (max-width: 767px) {
  .ui.search.dropdown .menu {
    max-height: 16rem;
  }
}

.ui.grid>.column:not(.row),
.ui.grid>.row>.column {
  text-align: right;
}

// UI STYLES
.ui.modal form,
.ui.modal form .actions {
  margin-bottom: 0;
}

.ui.modal {
  text-align: right;
}

.ui.modal .actions {
  direction: ltr;

  position: sticky;
  bottom: 0;
}



.ui.small.textarea,
.ui.form textarea.small {
  height: 3em;
}

.ui.xsmall.textarea,
.ui.form textarea.xsmall {
  height: 1.5em;
}

.ui.form .field>label {
  font-family: var(--header-font-family);
}

.ui.label {
  font-family: var(--kufi-font-family);
  font-weight: normal;
  border: 1px solid #d4d4d5;
  border-radius: 2px;

  &:empty {
    visibility: hidden;
  }
}

.ui.label.num {
  font-family: var(--numeric-font-family);
  unicode-bidi: embed;
  font-weight: bold;
  letter-spacing: 1px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.ui.label.capitalize {
  @extend .capitalize;
}

.ui.label.uppercase {
  @extend .uppercase
}

.ui.red.label,
.ui.red.labels .label {
  background-color: rgb(249, 71, 69) !important;
  border-color: rgb(249, 71, 69) !important;
}




.ui.white.label {
  color: #000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
}

.ui.category.search>.results .category>.name {
  padding: 0.9em 1em;
}

.ui.statistics .statistic>.label,
.ui.statistic>.label {
  display: block;
  padding: 0;
  font-size: 1em;
  line-height: 1em;
  white-space: nowrap;
  text-shadow: none;
  background-color: transparent;
}

.ui.statistic>.value, .ui.statistics .statistic>.value {
  font-family: var(--numeric-font-family);
}

.ui.segment {
  //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 200, 0.12);
}

.segment-container {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.ui.menu .ui.dropdown.new-invoice-dropdown .menu>.item {
  text-align: right;
  font-size: 1.1em !important;
  font-family: var(--kufi-font-family);
  padding: 1em 1.14285714em !important;

  .description {
    width: 25px;
  }
}

.ui.menu:not(.vertical) .right.item,
.ui.menu:not(.vertical) .right.menu {
  margin-left: 0 !important;
}

.ui.dropdown .menu>.item {
  &.green {
    background-color: #00b5ad;
  }
}

.form.rtl {
  .ui.input input {
    text-align: right;
  }
  .ui.dropdown .menu>.item {
    text-align: right;
  }
}

.ui.dropdown.rtl {
  .menu>.item {
    text-align: right;
  }
}

.ui.modal>.close.left {
  left: 1rem !important;
  right: auto !important;
}

// TOP MAGIC POPUP
.global-magic-popup {
  .ui.header {
    font-size: 18px;
    font-family: var(--kufi-font-family);
  }
  .ui.list>.item {
    font-size: 16px;
    font-family: var(--kufi-font-family);
    &:hover {
      color: #000 !important;
    }
  }
}

.ui.basic.modal.magic-modal {
  background: #fff;
  top: 25%;
  .close {
    color: #000;
  }
  .header {
    color: rgba(0, 0, 0, 0.87);
  }
  .ui.header {
    // color: #fff;
    font-family: var(--kufi-font-family);
    font-size: 18px;
  }
  .ui.link.list .item,
  .ui.link.list a.item,
  .ui.link.list .item a:not(.ui) {
    // color: #fff;
    font-size: 16px;
    font-family: var(--kufi-font-family);
  }
}

// .ui.table thead th,
// .ui.table th,
// .ui.table td {
//   text-align: right;
// }
.ui.table {
  text-align: right;
}

table.report {
  contain: strict;
}

.ui.table.no-border {
  border: none;
}

.ui.celled.table tr td, .ui.celled.table tr th {
  border-right: 1px solid rgba(34,36,38,.1);
  border-left: none;
}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
  border-left: none;
  border-right: none;
}

.table-responsive {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  overflow: visible;
  overflow-x: auto;
  min-height: 250px;
}

.min-height-auto {
  min-height: auto;
}

.ui.checkbox {
  //padding-right: 20px;
  //padding-left: 0;
}
.ui.checkbox input[type=checkbox] {
  left: auto;
  right: 0;

  float: none;
  margin-left: 0;
  margin-top: 0;
}

.ui.checkbox.ltr input[type=checkbox] {
  left: 0;
  right: auto;
}

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
  padding-left: 6.5rem;
}

.ui.toggle.checkbox label:empty {
  padding-left: 3.5rem;
}

.ui.checkbox.rtl .box, .ui.checkbox.rtl label {
  padding-left: 0;
  padding-right: 1.85714em;
}

.ui.checkbox.rtl .box:before, .ui.checkbox.rtl label:before {
  left: auto;
  right: 0;
}

.ui.checkbox.rtl .box:after, .ui.checkbox.rtl label:after {
  left: auto;
  right: 0;
}

//.ui.checkbox .box:after, .ui.checkbox label:after {
//
//}
.field.rtl {
  .ui.checkbox .box, .ui.checkbox label {
    padding-left: 0;
    padding-right: 1.85714em;
  }

  .ui.checkbox .box:before, .ui.checkbox label:before {
    left: auto;
    right: 0;
  }

  .ui.checkbox .box:after, .ui.checkbox label:after {
    left: auto;
    right: 0;
  }
}


.ui.checkbox {
  vertical-align: middle;
}

.ui.checkbox .box, .ui.checkbox label {
  cursor: pointer;
}

// Radio buttons
.radio_buttons.rtl {
  .ui.radio.checkbox .box, .ui.radio.checkbox label {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 1.85714em;
    //background: #f3f3f3;
    //box-shadow: 2px 0 3px 3px rgb(0 0 0 / 5%);
  }

  .ui.radio.checkbox .box:before,
  .ui.radio.checkbox label:before {
    left: auto;
    right: 0;
  }

  .ui.radio.checkbox .box:after,
  .ui.radio.checkbox label:after {
    left: auto;
    right: 0;
  }

  .ui.checkbox input[type=radio] {
    left: auto;
    right: 0;
  }

  .ui.radio.checkbox {
    margin-left: 3rem;
    margin-bottom: 1rem;
  }
}

.ui.form .grouped.fields.radio_buttons .field,
.ui.form .grouped.inline.fields.radio_buttons .field {
  display: flex;
  flex-direction: column;
}

.ui.form .grouped.fields.vertical.radio_buttons .field,
.ui.form .grouped.inline.vertical.fields.radio_buttons .field {
  display: flex;
  flex-direction: column;
}
.ui.form .grouped.fields.horizontal.radio_buttons .field,
.ui.form .grouped.inline.horizontal.fields.radio_buttons .field {
  display: flex;
  flex-direction: row;
}

input[type=radio]:checked + label {
  color: #006eff !important;
}



.rtl {
  .ui.dropdown .menu>.item {
    text-align: right;
  }

  .ui.radio.checkbox .box, .ui.radio.checkbox label {
    //padding-left: 0;
    padding-right: 1.85714em;
  }

  .ui.radio.checkbox .box:after, .ui.radio.checkbox label:after {
    left: auto;
    right: 0;
  }

  .ui.radio.checkbox .box:before, .ui.radio.checkbox label:before {
    left: auto;
    right: 0;
  }
}


.ui.radio.checkbox {
  padding-bottom: 10px;
}

.radio_buttons.horizontal {
  .field {
    flex-direction: row;
  }
}







.ui.bulleted.list .list>.item:before,
.ui.bulleted.list>.item:before,
ul.ui.list li:before {
  margin-left: 0;
  margin-right: -1.25rem;
}

.ui.bulleted.list,
ul.ui.list {
  margin-left: 0;
  margin-right: 1.25rem;
}

.ui.divider:not(.vertical):not(.horizontal) {
  &.blue {
    border-top: 1px solid #60CCFF;
    border-bottom: 1px solid #60CCFF;
  }
  &.dashed {
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
  &.thick {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }
}

.ui.transparent.divider {
  border-color: transparent!important;
}


// RTL
.ui.buttons .button:first-child {
  border-right: none;
  margin-right: 0em;
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  border-left: 1px solid rgba(34,36,38,.15);
}

.ui.buttons .button:last-child {
  border-top-left-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
  margin: 0 -.21428571em 0 .42857143em;
}

// block list
.ui.block.list a.item {
  // display: inline-block;
  padding: 40px;
  /* border: 1px solid #f1f1f1; */
  background-color: #fff;
  margin-bottom: 20px;
  margin-left: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #cfefff;
    border-right: 1px solid #00a1ff;
  }
}

.panel {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.ui.modal>.header {
  background: #f7f7f7;
  font-family: var(--header-font-family);
}

body.rtl .ui.fullscreen.modal>.close {
  right: auto;
  left: 1rem;
}

@media only screen and (max-width: 991px) {
  body.rtl .ui.modal>.close {
    right: auto;
    left: 1rem;
  }
}

.panel-toggle {
  .panel-title {
    cursor: pointer;
  }
  .panel-body {
    //transition: all 0.2s ease-in;
    height: 100%;
    padding: 10px 15px;
  }
  &.panel-collapsed .panel-body {
    height: 0;
    opacity: 0;
    padding: 0;
  }
}

.ui.steps {
  .step:after {
    left: 0;
    right: auto;
    border-width: 1px 0 0 1px;
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  }
  .step:first-child {
    border-right: none;
    border-radius: 0 .28571429rem .28571429rem 0;
  }
  .step:last-child {
    border-right: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem 0 0 .28571429rem;
  }
  .step .title {
    letter-spacing: 1px;
  }
  &.blue-color {
    .step.active {
      background-color: #08c;
      .title {
        color: #fff;
      }
    }
  }
}

.badge {
  &.status-badge {
    background: #f5f5f5;
    color: #000;
    padding: 6px;
    text-shadow: 0 -1px 0 rgba(211, 211, 211, 0.25);
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
  }

  &.status-badge.badge-success {
    color: #2d743f;
  }
}


.label {
  &.status-label {
    background: #f5f5f5;
    color: #555;
    text-shadow: 0 -1px 0 rgba(211, 211, 211, 0.25);
  }

  &.status-label.green {
    background-color: rgb(10, 141, 21) !important;
    color: #fff;
  }

  &.status-label.paid {
    background-color: rgb(10, 141, 21) !important;
    color: #fff;
  }
}


.statistic .num {
  font-family: var(--numeric-font-family);
  unicode-bidi: embed;
  letter-spacing: 0;
}

.statistic .num-bold {
  font-family: var(--numeric-font-family);
  font-weight: 500;
  unicode-bidi: embed;
  letter-spacing: 0;
}

.statistics.metadata .statistic {
  & > .label, & .value {
    text-align: right;
  }
}


.metadata-bar {
  margin-top: -20px !important;

  //border: 1px solid #f2f2f2;
  //box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-around;



  .metadata-bar__item,
  .item {
    background-color: #fff;
    //border-left: 1px solid #f2f2f2;
    padding: 30px 15px;

    min-height: 100px;

    display: inline-flex;
    flex: 1 0 25%;
    flex-direction: column;

    &:last-child {
      border-left: none;
    }

    .metadata-bar__item__label,
    .metadata-bar__item__value,
    & > .label,
    & > .value {
      display: block;
      padding: 0;
      font-size: 1em;
      line-height: 1em;
      white-space: nowrap;
      text-shadow: none;

      text-align: right;
    }
    .metadata-bar__item__label,
    & > .label {
      font-family: var(--base-font-family);
      font-weight: 700;
      color: rgba(0,0,0,.6);
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .metadata-bar__item__value,
    & > .value {
      font-family: var(--base-en-font-family);
      font-size: 1.5rem !important;

      &.num {
        font-family: var(--numeric-font-family);
        unicode-bidi: embed;
        letter-spacing: 0;
      }

      &.num-bold {
        font-family: var(--numeric-font-family);
        font-weight: 500;
        unicode-bidi: embed;
        letter-spacing: 0;
      }
    }
  }

  &.flex-20 {
    .metadata-bar__item,
    .item {
      flex: 1 0 20%;
    }
  }
}




.metadata-column {
  border: 1px solid #f2f2f2;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  //justify-content: space-between;
  //align-content: space-around;
  flex-direction: column;

  .header {
    background-color: #fff;
    padding: 10px;

    display: inline-flex;
  }


  .item {
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    padding: 25px 15px;

    display: inline-flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;

    &:hover {
      background-color: #eee;
    }

    &:last-child {
      border-bottom: none;
    }

    & > .label,
    & > .value {
      display: block;
      padding: 0;
      font-size: 1em;
      line-height: 1em;
      //white-space: nowrap;
      text-shadow: none;

      text-align: right;
    }

    & > .label {
      font-family: var(--base-font-family);
      font-weight: 700;
      color: rgba(0,0,0,.6);
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    & > .value {
      font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
      font-size: 1.5rem !important;

      &.num {
        font-family: var(--numeric-font-family);
        unicode-bidi: embed;
        letter-spacing: 0;
      }

      &.num-bold {
        font-family: var(--numeric-font-family);
        font-weight: 500;
        unicode-bidi: embed;
        letter-spacing: 0;
      }
    }
  }

  &.relaxed {
    .item {
      flex-direction: row;
      padding: 15px;

      & > .label {
        width: 30%;
        margin-bottom: 0;
      }

      & > .value {
        width: 70%;
        text-align: left;
        font-size: 1.2rem !important;
      }
    }
  }

  &.padded {
    .item {
      padding: 25px 15px;
    }
  }

  &.sidebar-variant {
    .item {
      padding: 15px;

      display: inline-flex;
      flex: 1 0 auto;
      flex-direction: row;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      & > .label,
      & > .value {
        width: 50%;
      }

      & > .label {
        margin-bottom: 0;
      }

      & > .value {
        font-size: 1.3rem !important;
        text-align: left;
      }
    }
  }
}


.ui.feed>.event>.content {
  text-align: right;
}

.ui.feed>.event>.label+.content {
  margin: .5em 1.14285714em .35714286em 0;
}


.rtl {
  .ui.avatar.images .image,
  .ui.avatar.images img,
  .ui.avatar.images svg,
  .ui.avatar.image img,
  .ui.avatar.image svg,
  .ui.avatar.image {
    margin-left: 0.25em;
    margin-right: 0;
  }


  .ui.comments .comment .metadata {
    margin-left: 0;
    margin-right: 0.5em;
  }
}


.field.primary-color {
  input {
    color: #0f76bc !important;
    border-color: #0f76bc !important;
  }
}

.ui.cards.stores-list {
  & .card {
    margin: 1.5em;

    //box-shadow: 0 50px 100px -20px rgb(0 0 0 / 20%), 0 0px 0px 1px #d4d4d5;
    box-shadow: 0px 20px 50px -13px rgb(0 0 0 / 20%), 0 0px 0px 1px #d4d4d5;

  }

  & > .card > .image > img {
    //width: 60%;
    margin: 0 auto;

    opacity: 0.7;
    filter: grayscale(1);
  }

  & .card:hover > .image > img {
    opacity: 1;
    filter: none;
  }
}



.ui.card.horizontal, .ui.horizontal.cards>.card {
  webkit-box-orient: horizontal;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 270px;
  width: 400px;
  max-width: 100%;

  & > .image {
    border-radius: .28571429rem 0 0 .28571429rem;
    width: 150px;

    display: flex;
    align-items: flex-start;

    & > img {
      width: 80%;
    }
  }

  & > .content {
    border-top: none;
    flex-basis: 1px;
  }
}



.ui.card.dashboard-card {
  margin-bottom: 2rem;

  box-shadow: 0px 20px 20px -20px rgb(0 0 0 / 20%);
  border: 1px solid #ccc !important;

  &:hover {
    transform: translateY(-2%);
    box-shadow: 0px 8px 11px -20px rgb(0 0 0 / 20%);
  }

  .ui.sub.header {
    height: 100px;
  }
}


// RTL
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input {
  border-top-left-radius: 0.285714rem;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.285714rem;
  border-bottom-right-radius: 0;
  border-left-color: rgba(34,36,38,.15);
  border-right-color: transparent;
}


.rtl {
  .ui.dropdown .menu>.item>.flag, .ui.dropdown .menu>.item>.icon, .ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>.label, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.flag, .ui.dropdown>.text>.icon, .ui.dropdown>.text>.image, .ui.dropdown>.text>.label, .ui.dropdown>.text>img {
    margin-right: 0;
    margin-left: 0.78571429rem;
  }
}

.star i.icon:before {
  content: "\f005";
}
.ui.rating .icon{
  padding:0;margin:0;text-align:center;font-weight:400;font-style:normal;flex:1 0 auto;cursor:pointer;width:1.25em;height:auto;transition:opacity .1s ease,background .1s ease,text-shadow .1s ease,color .1s ease
}
.ui.rating .icon{
  background:0 0;color:rgba(0,0,0,.15)
}    
.ui.star.rating .active.icon{
  background:0 0!important;color:#ffe623!important;text-shadow:0 -1px 0 #ddc507,-1px 0 0 #ddc507,0 1px 0 #ddc507,1px 0 0 #ddc507!important
}
.ui.star.rating .icon.selected,.ui.star.rating .icon.selected.active{
  background:0 0!important;color:#fc0!important;text-shadow:0 -1px 0 #e6a200,-1px 0 0 #e6a200,0 1px 0 #e6a200,1px 0 0 #e6a200!important
}

