/*
  ==================
  Fonts classes
  ===================
 */

.base-font {
  font-family: var(--base-font-family);
}

.gess-medium {
  font-family: var(--header-font-family);
}

.gess {
  font-family: var(--base-font-family);
}

.droid-kufi {
  font-family: var(--kufi-font-family);
}

.font-driod-naskh {
  font-family: var(--naskh-font-family);
}

.tahoma, .font-tahoma {
  font-family: tahoma, Arial, Helvetica, sans-serif !important;
}

.open-sans {
  font-family: 'Open Sans', sans-serif !important;
}

.rubik-font {
  font-family: Rubik, system-ui, Arial, sans-serif;
}


.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.text-align-start {
  text-align: start !important;
}

.text-align-end {
  text-align: end !important;
}


.text-line-through {
  text-decoration: line-through !important;
}


.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}


// Position helpers

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}


// Width

.w-0 {
  width: 0px;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.w-max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}


// Shadow
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


// Round

.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}



// Flex
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}


// Border
.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}


.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}


.border-0 {
  border-width: 0px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

//

.z-50 {
  z-index: 50;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0;
}



.tt-hint {
  padding-top: 6px !important;
}


.table {
  .td-centered {
    text-align: center;
    vertical-align: middle;
  }

  td.text-left,
  th.text-left {
    text-align: left;
  }
  td.text-right,
  th.text-right {
    text-align: right;
  }
  td.text-center,
  th.text-center {
    text-align: center;
  }
}

.discount-chkbox {
  margin-top: 30px !important;
  width: 50px !important;
  margin-left: 20px !important;
}
/*
  ====================================
  General Styles
  ====================================
 */

.rtl {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}





.blue {
  color: blue;
}

.red {
  color: rgb(249, 71, 69);
}

.orange {
  color: orange;
}

.alert {
  margin: 10px;
}

.hide {
  display: none;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.input-xs {
  width: 80px;
}

.input-small,
.select-small {
  width: 90px;
}

.input-xmedium {
  width: 150px;
}

.input-cmedium {
  width: 200px;
}

.input-medium {
  width: 250px;
}

.input-wide {
  width: 400px;
}

.col-centered,
.block-center {
  margin-right: auto !important;
  margin-left: auto !important;
  float: none !important;
}

.abs-tl {
  position: absolute;
  top: 0;
  left: 0;
}

.inline {
  display: inline;
}

.ib {
  display: inline-block;
}

.block {
  display: block;
}

.upcase {
  text-transform: uppercase;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.page-break {
  page-break-after: always;
}

.ccc {
  color: #ccc;
}

.mt0 {
  margin-top: 0 !important;
}

.mts {
  margin-top: 20px;
}

.mtm {
  margin-top: 40px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mbs {
  margin-bottom: 20px !important;
}

.mbm {
  margin-bottom: 30px;
}

.tl,
.table .tl {
  text-align: left;
}

.tr,
.table .tr {
  text-align: right;
}

.tc,
.table .tc {
  text-align: center;
}

.b {
  font-weight: bold;
}

.simple-wrapper {
  margin-bottom: 5px;
}

.hilight {
  background: #fffeaa;
  color: #000;
  padding: 2px 4px;
}

.as-num {
  font-family: var(--numeric-font-family);
  font-variant-numeric: tabular-nums;
  unicode-bidi: embed;
  letter-spacing: 0.08rem;

  &:empty {
    display: none;
  }

  &.bold {
    font-weight: 500;
  }
}

.num,
input[type='text'].num {
  font-family: var(--numeric-font-family);
  font-variant-numeric: tabular-nums;
  //font-weight: 500;
  unicode-bidi: embed;
  letter-spacing: 0.08rem;
  font-size: 1em;
  //line-height: 1em;
}

span.num, .num {
  padding: 3px;
  direction: ltr;

  &:empty {
    display: none;
  }

  &.bold {
    font-weight: 500;
  }

  &.big{
    font-size: 1.3rem;
  }

  &.very.big {
    font-size: 1.6em;
    font-family: var(--numeric-font-family);
  }

  &.large {
    font-size: 2em;
    font-family: var(--numeric-font-family);
  }

  &.very.large {
    font-size: 2.3em;
    font-family: var(--numeric-font-family);
  }
}

td.num:empty {
  display: table-cell;
}

.num-s {
  display: inline-block;
  padding: 0 5px;
}

.num-mid {
  font-family: var(--numeric-font-family);
  font-size: 18px;
}

.num-large {
  font-family: var(--numeric-font-family);
  font-size: 20px;
  line-height: 20px;
}

.num-xlarge {
  font-family: var(--numeric-font-family);
  font-size: 24px;
  line-height: 24px;
}

.num-xxlarge {
  font-family: var(--numeric-font-family);
  font-size: 34px;
  line-height: 34px;
}

.vat {
  vertical-align: top;
}

.bottom-actions {
  margin-top: 20px;
}

.no_bullets {
  list-style: none;
}


.t-state {
  vertical-align: middle;
  text-align: center !important;
}


/*  search field */

a.search-item {
  color: #000;
}

a.search-item:hover {
  color: #fff;
  text-decoration: none;
}


/* ======================= */

.date-range-form input.datepicker {
  height: 26px;
  margin-top: 0;
}

.bg.orage {
  background: #F9E3BE !important;
}


/*
  ===============================
  Filters
  ===============================
 */

.col-filters i {
  display: none;
}

.col-filters .col-visible i {
  display: inline-block;
}

//  MISC
.js-btn-disable {
  opacity: .2;
  pointer-events: none;
  cursor: not-allowed;
}

.js-el-disable {
  pointer-events: none;
  cursor: not-allowed;
}

.ui.button.hidden-submit,
.hidden-submit {
  border: 0 none;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.js-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.js-hidden {
  display: none !important;
  visibility: hidden !important;
}

.small-caps {
  text-transform: uppercase;
}

.m0 {
  margin: 0;
}

.image-middle {
  vertical-align: middle;
}

.image-white-border {
  border: 1px solid #fff;
}

.my-row {
  display: flex;
  .item {
    flex-grow: 3;
  }
}

.grey {
  color: #6d6d6d;
}

.meta-item {
  margin-bottom: 5px;
  .meta-item--header {
    margin-bottom: 10px;
  }
  &.grey {
    color: #6d6d6d !important;
  }
  .meta-item--label {}
  .fixed-width {
    width: 100px;
    display: inline-block;
  }
  .fixed-width-large {
    width: 120px;
    display: inline-block;
  }
  &.mb-mini {
    margin-bottom: 6px;
  }
}

.underline {
  padding-bottom: 5px;
  border-bottom: 1px solid #7e7e7e;
}

.color-black {
  color: #000;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.mrs {
  margin-right: 20px;
}

.mrm {
  margin-right: 40px;
}

.mrl {
  margin-right: 60px;
}

.mls {
  margin-left: 20px;
}

.mlm {
  margin-left: 40px;
}

.mll {
  margin-left: 60px;
}

.mts {
  margin-top: 20px;
}

.mtm {
  margin-top: 40px;
}

.mtl {
  margin-top: 60px;
}

.mbs {
  margin-bottom: 20px;
}

.mbm {
  margin-bottom: 40px;
}

.mbl {
  margin-bottom: 60px;
}

.mb-mini {
  margin-bottom: 10px;
}

.mt0 {
  margin-top: 0;
}

.mr0 {
  margin-right: 0;
}

.mb0 {
  margin-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.m0 {
  margin: 0;
}

.pad-s {
  padding: 5px;
}

.pad-m {
  padding: 10px;
}

.s-pad-h {
  padding-left: 10px;
  padding-right: 10px;
}

.s-pad-v {
  padding-top: 10px;
  padding-bottom: 10px;
}

.white-color {
  color: #fff;
}

.green-color {
  color: green;
}

.text-grey,
.text-gray {
  color: #a4aeb3;
}

.text-gray-1 {
  color: #898989;
}

.blue-color {
  color: #0d75bc;
}

.blue-bg {
  background-color: #0d75bc;
}

.border-top-blue {
  border-top: 1px solid #0d75bc;

  &.double {
    border-top-width: 2px;
  }
}

.red-color {
  color: red;
}

.light-blue-color {
  color: #08c;
}

.black-color {
  color: #000;
}

.table thead tr.valign-bottom td {
  vertical-align: bottom;
}

.valign-top {
  vertical-align: top;
}

.tax-total-col-bg {
  background-color: #fafafa;
  .num {
    line-height: 2em;
  }
}

.tax-total-col-dark-bg {
  background-color: #e6e6e6;
  .num {
    line-height: 2em;
  }
}


.border-left-1 {
  border-left: 1px solid #ccc;
}


.box-shadow {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12);
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-word {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
